<template>
  <div id="examine">
    <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/finance/examineList' }">进入人工审核</el-breadcrumb-item>
      <el-breadcrumb-item>进件详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row>
        <el-col :span="8"><p class="font">最高学历: {{examineData.education}}</p></el-col>
        <el-col :span="8"><p class="font">子女数量: {{examineData.childrenCount}}</p></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><p class="font">月收入: {{examineData.monthlyIncome}}</p></el-col>
        <el-col :span="8"><p class="font">工作年限: {{examineData.workingSeniority}}</p></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><p class="font">邮箱: {{examine.loanInfo.email}}</p></el-col>
        <el-col :span="8"><p class="font">是否逾期借款: {{examineData.overdue}}</p></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><p class="font">whatsApp: {{examine.loanInfo.whatsApp}}</p></el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="8"><p class="font">关系类型: {{examineData.emergencyContact1Relationship}}</p></el-col>
        <el-col :span="8"><p class="font">联系电话: {{examine.loanInfo.emergencyContact1PhoneNumber}}</p></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><p class="font">联系人姓名: {{examine.loanInfo.emergencyContact1Name}}</p></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><p class="font">关系类型: {{examineData.emergencyContact2Relationship}}</p></el-col>
        <el-col :span="8"><p class="font">联系电话: {{examine.loanInfo.emergencyContact2PhoneNumber}}</p></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><p class="font">联系人姓名: {{examine.loanInfo.emergencyContact2Name}}</p></el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="8"><p class="font">证件照正面</p></el-col>
        <el-col :span="8"><p class="font">证件照反面</p></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"> <el-image
            style="width: 100px; height: 100px"
            :src="examine.loanInfo.frontIdnumberPicUrl"
            fit="contain"
            :preview-src-list="IdnumberPicUrl"></el-image></el-col>
        <el-col :span="8"><el-image
            style="width: 100px; height: 100px"
            :src="examine.loanInfo.backIdnumberPicUrl"
            fit="contain"
            :preview-src-list="IdnumberPicUrl"></el-image></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><p class="font">姓名: {{examine.loanInfo.name}}</p></el-col>
        <el-col :span="8"><p class="font">父亲的姓氏: {{examine.loanInfo.paternalSurname}}</p></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><p class="font">母亲的姓氏: {{examine.loanInfo.maternalSurname}}</p></el-col>
        <el-col :span="8"><p class="font">证件号码(CURP): {{examine.loanInfo.idNumber}}</p></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><p class="font">出生日期: {{examine.loanInfo.birthday}}</p></el-col>
        <el-col :span="8"><p class="font">性别: {{examineData.sex}}</p></el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="4" v-for="(item, i) in examine.auditPicPathList"><p class="font">头像照{{i + 1}}</p></el-col>
      </el-row>
      <el-row>
        <el-col :span="4" v-for="(item, i) in examine.auditPicPathList"> <el-image
            style="width: 100px; height: 100px"
            :src="item"
            fit="contain"
            :preview-src-list="examine.auditPicPathList"></el-image></el-col>
      </el-row>
      <el-divider></el-divider>
      <el-form :model="editForm"
      >
        <el-form-item label="审批结果:" prop="status">
          <el-radio v-model="editForm.status" label="approval" v-if="examine.status == 0">通过</el-radio>
          <el-radio v-model="editForm.status" label="deny" v-if="examine.status == 0">拒绝</el-radio>
          <el-tag type="success" v-if="examine.status==1">审核通过</el-tag>
          <el-tag type="danger" v-if="examine.status==-1">审核拒绝</el-tag>
        </el-form-item>
        <el-form-item label="审批意见:" prop="username">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="editForm.comment"
              v-if="examine.status == 0"
              maxlength="500"
              style="width: 500px">
          </el-input>
          <div v-else>{{examine.comment}}</div>
        </el-form-item>
        <el-form-item>
          <el-button
              type="primary"
              @click="manualApproval"
              :disabled="examine.status != 0"
          >提交</el-button>

          <el-button
              type="info"
              @click=" goBack()"
          >返回</el-button>
        </el-form-item>
      </el-form>

    </el-card>
  </div>
</template>

<script>
export default {
  name: "examine",
  data() {
    return {
      examine: {},
      dict: [],
      examineData: {},
      editForm: {},
      IdnumberPicUrl: []
    }
  },
  methods: {
    /**
     * 审批
     */
    async manualApproval() {
      const {data: res} = await this.$http.put("/system/loan/manualApproval", this.editForm);
      if (res.success == true) {
        this.examine.status = this.editForm.status == 'approval' ? 1 : -1
        this.examine.comment = this.editForm.comment
        return this.$message.success("审批成功");
      } else {
        return this.$message.error(res.data);
      }
    },
    /**
     * 返回
     */
    goBack() {
      this.$router.push({
        path: "/finance/examineList"
      });
    },

    /**
     * 获取数据字典
     */
    async getDict() {
      const {data: res} = await this.$http.get("/system/loan/datadic/zh-CN");
      this.dict = res.data.dataDic
      this.dataCollation()
    },

    /**
     * 数据整理
     */
    dataCollation() {
      let examine = this.examine;
      let examineData = {};
      let dict = this.dict;
      let IdnumberPicUrl = [];
      for (let i = 0; i < dict.length; i++) {
        if (dict[i].type == 'education' && examine.loanInfo.education == dict[i].value) {
          examineData.education = dict[i].content
        }
        if (dict[i].type == 'children_count' && examine.loanInfo.childrenCount == dict[i].value) {
          examineData.childrenCount = dict[i].content
        }
        if (dict[i].type == 'monthly_income' && examine.loanInfo.monthlyIncome == dict[i].value) {
          examineData.monthlyIncome = dict[i].content
        }
        if (dict[i].type == 'working_seniority' && examine.loanInfo.workingSeniority == dict[i].value) {
          examineData.workingSeniority = dict[i].content
        }
        if (dict[i].type == 'overdue' && examine.loanInfo.overdue == dict[i].value) {
          examineData.overdue = dict[i].content
        }
        if (dict[i].type == 'contact_relationship1' && examine.loanInfo.emergencyContact1Relationship == dict[i].value) {
          examineData.emergencyContact1Relationship = dict[i].content
        }
        if (dict[i].type == 'contact_relationship2' && examine.loanInfo.emergencyContact2Relationship == dict[i].value) {
          examineData.emergencyContact2Relationship = dict[i].content
        }
        if (dict[i].type == 'sex' && examine.loanInfo.sex == dict[i].value) {
          examineData.sex = dict[i].content
        }
        if (dict[i].type == 'card_type' && examine.loanInfo.cardType == dict[i].value) {
          examineData.cardType = dict[i].content
        }
        if (dict[i].type == 'card_type' && examine.loanInfo.cardType == dict[i].value) {
          examineData.cardType = dict[i].content
        }
      }
      IdnumberPicUrl.push(examine.loanInfo.frontIdnumberPicUrl)
      IdnumberPicUrl.push(examine.loanInfo.backIdnumberPicUrl)
      this.IdnumberPicUrl = IdnumberPicUrl
      this.examineData = examineData
      this.editForm = {
        applyId: examine.id,
        status: examine.loanInfo.approvalStatus,

      }
    },

  },
  /**
   * 页面加载
   */
  created() {
    this.examine = JSON.parse(this.$route.query.examine);
    console.log(this.examine)
    this.getDict()
  }
}
</script>

<style scoped>
.font {
  font-size: 14px;
}
</style>